// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            ion-item > ion-label > h3 {
                font-weight: bolder;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/shared/components/edge/offline/offline.component.ts"],"names":[],"mappings":";YACY;gBACI,mBAAmB;YACvB","sourcesContent":["\n            ion-item > ion-label > h3 {\n                font-weight: bolder;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
