// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host {
            height: 100%;
            font-size: 0.9em;
        }
    `, "",{"version":3,"sources":["webpack://./src/app/shared/components/modal/modal.ts"],"names":[],"mappings":";QACQ;YACI,YAAY;YACZ,gBAAgB;QACpB","sourcesContent":["\n        :host {\n            height: 100%;\n            font-size: 0.9em;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
