// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host {
        :is(ion-card) {
            cursor: auto !important;;
        }
    }
    `, "",{"version":3,"sources":["webpack://./src/app/edge/settings/system/maintenance/maintenance.ts"],"names":[],"mappings":";IACI;QACI;YACI,uBAAuB;QAC3B;IACJ","sourcesContent":["\n    :host {\n        :is(ion-card) {\n            cursor: auto !important;;\n        }\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
