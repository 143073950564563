export enum GridMode {
    UNDEFINED = 0,
    ON_GRID = 1,
    OFF_GRID = 2,
}
export enum Mode {
    MANUAL_ON = "MANUAL_ON",
    MANUAL_OFF = "MANUAL_OFF",
    AUTOMATIC = "AUTOMATIC",
}
export const ChartAnnotationState = {
    ON: 1,
    OFF: 0,
    OFF_HIDDEN: null,
};
export enum WorkMode {
    TIME = "TIME",
    NONE = "NONE",
}
export enum OverrideStatus {
    ACTIVE = 0,
    INACTIVE = 1,
    ERROR = 2,
}
