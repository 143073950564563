// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .ion-col-with-left-and-right-icon {
            display: flex;
            justify-content: center;

            ion-text {
                padding-left: 1%;
                padding-right: 1%;
                align-self: center;
            }
        }
    `, "",{"version":3,"sources":["webpack://./src/app/shared/components/pull-to-refresh/pull-to-refresh.ts"],"names":[],"mappings":";QACQ;YACI,aAAa;YACb,uBAAuB;;YAEvB;gBACI,gBAAgB;gBAChB,iBAAiB;gBACjB,kBAAkB;YACtB;QACJ","sourcesContent":["\n        .ion-col-with-left-and-right-icon {\n            display: flex;\n            justify-content: center;\n\n            ion-text {\n                padding-left: 1%;\n                padding-right: 1%;\n                align-self: center;\n            }\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
